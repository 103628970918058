<template>
  <div class="userLogin">
    <div class="loginBox">
      <div class="loginFrom">
        <!-- logo图片 -->
        <!-- <img src="../../assets/img/credis-logo.png"
             alt=""> -->
        <div class="fromBox">
          <!-- 标题 -->
          <h1>Reset Password</h1>
          <p>Reset buyer account password</p>
          <!-- from表单 -->
          <el-form :model="loginForm"
                   :rules="loginRules"
                   ref="loginForm"
                   @submit.native.prevent
                   class="demo-ruleForm">
            <!-- 邮箱 -->
            <el-form-item prop="loginEmail">
              <el-input placeholder="Please input email"
                        v-model="loginForm.loginEmail"></el-input>
            </el-form-item>
            <!-- 发送按钮 -->
            <el-form-item>
              <el-button type="primary"
                         @click="forgetPass()" :loading="$store.state.loadingBool">Reset</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 引入接口
import {
  modifyEmail // 修改密码第一步
} from '../../api/buyerFront'
export default {
  data () {
    // 登录界面邮箱验证
    var email = (rule, value, callback) => {
      // 邮箱正则
      const reg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/
      // 邮箱value值判定
      if (!value) {
        callback(new Error('Mailbox cannot be empty'))
      } else if (!reg.test(value)) {
        callback(new Error('Please input correct email format'))
      } else {
        callback()
      }
    }
    return {
      // form表单
      loginForm: {
        loginEmail: '', // 邮箱
        loginRole: 'RULE_BUYER' // 用户身份
      },
      // 表单验证规则
      loginRules: {
        // 邮箱验证规则
        loginEmail: [
          { validator: email, trigger: 'blur' }
        ]
      },
      loadingBool: false
    }
  },
  methods: {
    // 修改密码
    forgetPass () {
      const that = this
      // 判断邮箱是否为空
      if (this.loginForm.loginEmail !== '') {
        this.$store.commit('getLoading', true)
        that.$store.state.loadingBool = true
        // 调用修改密码第一步接口
        modifyEmail({
          mail: this.loginForm.loginEmail, // 邮箱
          role: this.loginForm.loginRole // 用户身份
        }).then(result => {
          that.$store.state.loadingBool = false
          // 接口调用成功且状态码为200
          if (result.data.code === 200) {
            this.$store.commit('getLoading', false)
            this.$message.success(result.data.message) // 弹框提示
          } else {
            this.$store.commit('getLoading', false)
            this.$message.error(result.data.message) // 弹框提示
          }
          // ('result ==>', result)
        }).catch(err => {
          return err
        })
      } else {
        this.$message.error('Please input email') // 弹框提示
      }
    }
  }
}
</script>

<style lang="less" scoped>
.userLogin {
  min-height: 600px;
  .loginBox {
    min-height: 550px;
    position: relative;
    .loginFrom {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 500px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      img {
        display: block;
        width: auto;
        height: 40px;
      }
      .fromBox {
        background-color: white;
        padding: 25px;
        width: 500px;
        border: 1px solid #eeeeee;
        h1 {
          background-color: #f8ac59;
          font-size: 24px;
          font-weight: normal;
          line-height: 30px;
          color: white;
          padding: 15px 0;
        }
        p {
          font-size: 16px;
          line-height: 24px;
          color: #6c757d;
          text-align: left;
          padding-left: 25px;
          margin-top: 25px;
        }
        .el-form {
          padding: 0 25px;
          .el-form-item {
            margin: 20px 0 0 0;
            &:nth-child(3) {
              margin: 0;
            }
          }
          .el-button {
            float: left;
            width: 20%;
            background-color: #f8ac59;
            border-color: #f8ac59;
          }
        }
      }
    }
  }
  // background: red;
}
</style>
